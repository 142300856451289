@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sass:math';

.case-link {
  $breakpoint: 950px;

  display: flex;
  flex-wrap: wrap;
  gap: spacing.$s;
  text-decoration: none;

  width: 100%;
  border-radius: spacing.$s;
  padding: spacing.$s spacing.$l;
  color: light.$on-surface-primary-default;
  background-color: light.$surface-primary-default;
  margin-bottom: spacing.$xs;

  @media (min-width: $breakpoint) {
    display: grid;
    align-items: center;
    grid-template-columns: 25% 25% 18% 1fr 1fr;
  }

  &:hover {
    background-color: light.$surface-primary-hover;
  }

  &__item {
    @media (max-width: $breakpoint) {
      flex: 1;
      min-width: 0;

      &:nth-child(1),
      &:nth-child(5) {
        order: 1;
      }

      &:nth-child(2) {
        flex: 0 0 100%;
        order: 2;
      }

      &:nth-child(3),
      &:nth-child(4) {
        order: 3;
      }
    }
  }

  &__info {
    &--secondary {
      color: light.$on-surface-primary-alternate;
    }

    &--end {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      @media (min-width: $breakpoint) {
        display: block;
      }
    }
  }

  &__tag {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    @media (min-width: $breakpoint) {
      display: inline-block;
      justify-self: end;
    }
  }

  &__category-wrapper {
    display: inline-block;
  }

  &__category {
    display: inline-flex;
    align-items: center;
    color: light.$on-surface-secondary-default;
    background-color: light.$surface-secondary-default;
    padding: spacing.$xs spacing.$s;
    border-radius: 4px;
  }

  &__sub-category {
    color: light.$on-surface-primary-default;
    background-color: light.$surface-primary-default;
    padding: spacing.$xxs spacing.$xs;
    border-radius: 4px;
    margin-left: spacing.$s;
  }
}
